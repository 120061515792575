<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="my-account">
			<div class="account-wrap">
				<div class="account-left">
					<div class="title">总授信额度(元)</div>
					<div class="money">
						<div class="balance-money">
							<b>{{ sumgrowth }}</b>
							<!-- .
							<span>{{ decimal }}</span> -->
						</div>
					</div>
				</div>
				<div class="account-left">
					<div class="title">剩余授信额度(元)</div>
					<div class="money">
						<div class="balance-money">
							<b>{{ growth }}</b>
							<!-- .
							<span>{{ decimal }}</span> -->
						</div>
					</div>
				</div>
				<div class="account-left">
					<div class="title">当前企业人数</div>
					<div class="money">
						<div class="balance-money">
							<b>{{ count }}</b>
							<!-- 	.
							<span>{{ decimal }}</span> -->
						</div>
					</div>
				</div>
				<div class="account-left">
					<!-- <div class="title" style='cursor: pointer;' @click="dialogVisible=true">添加企业分组</div> -->
					<div class="btn"><el-button type="primary" size="medium" style='margin-top: 20px;' @click="dialogVisible=true">添加企业分组</el-button></div>
				</div>
			</div>
			<div class="detail" v-loading="loading">
				<el-table :data="listData" border>
					<el-table-column prop="fenzu_name" label="分组名称" width="150"></el-table-column>
					<el-table-column prop="user_count" label="组内人数" width="150"></el-table-column>
					<el-table-column prop="growth_sum" class="detail-name" label="总授信额度(元)"></el-table-column>
					<el-table-column prop="growth_balance" label="剩余授信额度(元)"></el-table-column>
					<el-table-column prop="add_time" label="创建时间"></el-table-column>
					<el-table-column prop="time" label="操作">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleEdit(scope.row)">详情</el-button>
							<el-button size="mini" @click="del(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 	<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="account.page" :page-size.sync="account.page_size"
					@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
					hide-on-single-page></el-pagination>
			</div> -->
		</div>

		<el-dialog title="添加企业分组" :visible.sync="dialogVisible" width="30%">
			<div>
				<el-form ref="form" label-width="80px">
					<el-form-item label="分组名称">
						<el-input v-model="fenzu_name"></el-input>
					</el-form-item>
					<el-form-item label="分配额度">
						<el-input v-model="growth_sum" :min='0' type='number'></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" :loading='addLoding' @click="tianjiafenzu">确定添加</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		balance,
		withdrawConfig,
		balanceDetail,
		memberaccount,
		fmemberGroup,
		memberGroup,
		memberGroupdel
	} from "@/api/member/account"
	export default {
		name: "account",
		components: {},
		data: () => {
			return {
				listData: [],
				addLoding: false,
				growth_sum: '',
				fenzu_name: '',
				dialogVisible: false,
				sumgrowth: 0,
				growth: 0,
				count: 0,
				account: {
					page: 1,
					page_size: 10
				},
				balanceInfo: {
					balance: 0,
					balance_money: 0
				},
				accountList: [],
				total: 0,
				integer: 0,
				decimal: 0,
				loading: true,
				yes: true
			}
		},
		created() {
			this.getAccount(), this.getAccountList()
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			del(e) {
				console.log(e)
				this.$confirm('确定删除该分组吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					memberGroupdel({
							fenzu_id: e.fenzu_id
						})
						.then(res => {
							this.addLoding = false;
							console.log(res)
							if (res.code == 0) {
								this.$message.success('删除成功')
								this.getAccount();
								this.getAccountList();
							}
							this.fenpeidialogVisible = false;
							this.loading = false
						})
						.catch(err => {
							this.addLoding = false;
							this.loading = false
							this.$message.error(err.message)
						})
			
			
			
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() => {
			
				});
			},
			handleEdit(e) {
				this.$router.push('/member/fenzu_detail?fenzuid=' + e.fenzu_id+'&name='+e.fenzu_name)
			},
			tianjiafenzu() {
				if (!this.fenzu_name) {
					this.$message({
						message: '请输入分组名称',
						type: 'warning'
					});
					return
				};
				if (!this.growth_sum) {
					this.$message({
						message: '请输入分配额度',
						type: 'warning'
					});
					return
				};
				if (this.growth_sum < 0) {
					this.$message({
						message: '分配额度不能为负数',
						type: 'warning'
					});
					return
				};
				this.addLoding = true;
				memberGroup({
						fenzu_name: this.fenzu_name,
						growth_sum: this.growth_sum
					})
					.then(res => {
						this.addLoding = false;
						console.log(res)
						if (res.code == 0) {
							this.$message.success('添加成功')
							this.getAccount();

						}
						this.dialogVisible = false;
						this.loading = false
					})
					.catch(err => {
						this.addLoding = false;
						this.loading = false
						this.$message.error(err.message)
					})

			},
			//获取余额信息
			getAccount() {
				console.log('memberaccount')
				fmemberGroup({})
					.then(res => {
						console.log(res)
						if (res.code == 0) {
							this.sumgrowth = res.sumgrowth;
							this.growth = res.growth;
							this.count = res.count;
							this.listData = res.data;
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
						this.$message.error(err.message)
					})
			},
			//获取余额明细
			getAccountList() {
				balanceDetail({
						page_size: this.account.page_size,
						page: this.account.page,
						account_type: "balance"
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.accountList = res.data.list
							this.total = res.data.count
							this.accountList.forEach(item => {
								item.time = this.$util.timeStampTurnTime(item.create_time)
							})
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			handlePageSizeChange(num) {
				this.account.page_size = num
				this.getAccountList()
			},
			handleCurrentPageChange(page) {
				this.account.page = page
				this.getAccountList()
			},
			applyWithdrawal() {
				this.$router.push("/member/apply_withdrawal")
			},
			rechargeList() {
				this.$router.push("/member/recharge_list")
			}
		}
	}
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.my-account {
		background: #ffffff;
		padding: 20px;

		.account-wrap {
			display: flex;
			margin-bottom: 10px;

			.account-left {
				flex: 1;

				.title {
					font-size: $ns-font-size-base;
					font-weight: 600;
				}

				.money {
					display: flex;

					.balance-money {
						b {
							font-size: 30px;
						}

						span {
							font-weight: 600;
						}
					}

					.tx {
						color: $base-color;
						margin-left: 5px;
						margin-top: 20px;
						cursor: pointer;
					}

					.cz {
						color: $base-color;
						margin-left: 5px;
						margin-top: 20px;
						cursor: pointer;
					}
				}
			}

			.account-right {
				flex: 1;
				font-size: $ns-font-size-base;
				display: flex;
				align-items: center;

				.item {
					display: flex;
					align-items: center;

					.title {
						margin-left: 3px;
					}

					.num {
						margin-left: 3px;
					}
				}
			}
		}

		.page {
			display: flex;
			justify-content: center;
			align-content: center;
			padding-top: 20px;
		}
	}
</style>